//BRAND
export const name = 'CREEBTECH';
export const company = 'Rookslab';
export const websiteURL = 'http://localhost:3000';
export const serverURL = 'https://skillviaa.onrender.com';
export const logo = 'https://firebasestorage.googleapis.com/v0/b/skillviaa.appspot.com/o/creativity%20(2).png?alt=media&token=414ce499-6606-4f21-b542-c05027c6f944';
export const razorpayEnabled = false;
export const paypalEnabled = true;
export const stripeEnabled = false;
export const paystackEnabled = true;
//PRICING :-

//FREE 
export const FreeType = 'Free Plan';
export const FreeCost = 0;
export const FreeTime = '';

//MONTHLY 
export const MonthType = 'Monthly Plan';
export const MonthCost = 5;
export const MonthTime = 'month';

//YEARLY 
export const YearType = 'Yearly Plan';
export const YearCost = 50;
export const YearTime = 'year';

//TESTIMONIAL
export const review = "CREEBTECH is a Top Notch Learning Platform Powered By AI, its a seemless way to make learning easier with just a click of a button, Creebtech use the power of Ai to Fetch detailed Resource to create a compelling and Comprehensive Course Thats will impact users Positively. It's time-saving and Easy to Use. its a highly recommend platform for effective learning especially for student who desire efficient and impact online learning experience.";
export const from = "Umar Faruq";
export const profession = 'CFO at Rookslab';
export const photoURL = 'https://skillviaa.web.app/static/media/logo.27299021ff83911846c44332704fb169.svg';

//PAYPAL
export const paypalPlanIdOne = "P-2LH44645GM679744UM2HBQFI";
export const paypalPlanIdTwo = "P-7KG50632AT861311JM2HBSAY";

//RAZORPAY
export const razorpayKeyId = "rzp_test_uqALjZHyTyuiOm";
export const razorpayPlanIdOne = "plan_NMvvtDfznbRp6V";
export const razorpayPlanIdTwo = "plan_NMRc9HARBQRLWA";

//STRIPE
export const stripePlanIdOne = "price_1OTo7CSDXmLtVnVeaHIHxqCj";
export const stripePlanIdTwo = "price_1OTo7eSDXmLtVnVeBbn82U5B";

//PAYSTACK
export const paystackPlanIdOne = "PLN_thfqi1tr7wqggfi";
export const paystackPlanIdTwo = "PLN_enakqy6lsakryc3";
export const amountInNgnOne = '8000';
export const amountInNgnTwo = '80000';
